var render, staticRenderFns
import script from "./system.vue?vue&type=script&lang=js&"
export * from "./system.vue?vue&type=script&lang=js&"
import style0 from "./system.vue?vue&type=style&index=0&id=edb0dba2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edb0dba2",
  null
  
)

export default component.exports