<script>
export default {
  data() {
    return {
      loading: true,
      submitLoading: false,
      form: {
        login_img: '',
        admin_copyright_content: '',
        admin_copyright_icon: '',
        admin_copyright_link: '',
        mobile_copyright_content: '',
        mobile_copyright_icon: ''
      },
      rules: {
        admin_copyright_content: [
          {
            required: true,
            message: '版权内容不能为空',
            trigger: 'blur'
          }
        ],
        mobile_copyright_content: [
          {
            required: true,
            message: '版权内容不能为空',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  render() {
    return (
      <div>
        <el-form
          label-width="200px"
          props={{
            rules: this.rules,
            model: this.form
          }}
          v-loading={this.loading}>
          <div class="le-card">
            <div class="le-head flex align-center">
              <span class="le-title-line" />
              <span class="le-title">背景图</span>
            </div>
            <el-form-item label="登录页背景图">
              <div class="select-cover__120">
                <pictureDialog
                  limit={1}
                  v-model={this.form.login_img}
                  scopedSlots={{
                    upload: () => {
                      return (
                        <div class="select-cover__120-add">
                          <i class="le-icon le-icon-add select-cover__120-icon" />
                          <span class="select-cover__120-text">添加图片</span>
                        </div>
                      );
                    },
                    preview: scope => {
                      return (
                        <div class="select-cover__120-edit">
                          <el-image src={scope.url} fit="cover" />
                          <div class="select-cover__120-tips">
                            <span>替换</span> |<span>删除</span>
                          </div>
                        </div>
                      );
                    }
                  }}></pictureDialog>
              </div>
              <div class="le-prompt-text">建议尺寸：400像素*512像素</div>
            </el-form-item>
          </div>
          <div class="le-card">
            <div class="le-head flex align-center">
              <span class="le-title-line" />
              <span class="le-title">后台版权</span>
            </div>
            <el-form-item label="版权内容" key="admin_copyright_content" prop="admin_copyright_content">
              <el-input v-model={this.form.admin_copyright_content} placeholder="请输入版权内容" />
            </el-form-item>
            <el-form-item label="版权图标">
              <div class="select-cover__120">
                <pictureDialog
                  limit={1}
                  v-model={this.form.admin_copyright_icon}
                  scopedSlots={{
                    upload: () => {
                      return (
                        <div class="select-cover__120-add">
                          <i class="le-icon le-icon-add select-cover__120-icon" />
                          <span class="select-cover__120-text">添加图片</span>
                        </div>
                      );
                    },
                    preview: scope => {
                      return (
                        <div class="select-cover__120-edit">
                          <el-image src={scope.url} fit="cover" />
                          <div class="select-cover__120-tips">
                            <span>替换</span> |<span>删除</span>
                          </div>
                        </div>
                      );
                    }
                  }}></pictureDialog>
              </div>
              <div class="le-prompt-text">建议尺寸：240像素*48像素，不超过2M</div>
            </el-form-item>
            <el-form-item label="版权跳转链接">
              <el-input v-model={this.form.admin_copyright_link} placeholder="请输入版权跳转链接" />
            </el-form-item>
          </div>
          <div class="le-card">
            <div class="le-head flex align-center">
              <span class="le-title-line" />
              <span class="le-title">手机端版权</span>
            </div>
            <el-form-item label="版权内容" key="mobile_copyright_content" prop="mobile_copyright_content">
              <el-input v-model={this.form.mobile_copyright_content} placeholder="请输入版权内容" />
            </el-form-item>
            <el-form-item label="版权图标">
              <div class="select-cover__120">
                <pictureDialog
                  limit={1}
                  v-model={this.form.mobile_copyright_icon}
                  scopedSlots={{
                    upload: () => {
                      return (
                        <div class="select-cover__120-add">
                          <i class="le-icon le-icon-add select-cover__120-icon" />
                          <span class="select-cover__120-text">添加图片</span>
                        </div>
                      );
                    },
                    preview: scope => {
                      return (
                        <div class="select-cover__120-edit">
                          <el-image src={scope.url} fit="cover" />
                          <div class="select-cover__120-tips">
                            <span>替换</span> |<span>删除</span>
                          </div>
                        </div>
                      );
                    }
                  }}></pictureDialog>
              </div>
              <div class="le-prompt-text">建议尺寸：240像素*48像素，不超过2M</div>
            </el-form-item>
          </div>
        </el-form>
        <div class="le-cardpin">
          <el-button type="primary" disabled={this.loading} loading={this.submitLoading} onClick={this.submit}>
            保存
          </el-button>
        </div>
      </div>
    );
  },
  methods: {
    submit: function () {
      this.submitLoading = true;
      this.$heshop
        .setting('post', {
          keyword: 'copyright_information',
          content: this.form
        })
        .then(() => {
          this.submitLoading = false;
          this.$message.success('保存成功');
        })
        .catch(err => {
          this.$message.error(err.data.message);
        });
    },
    getDetail: function () {
      this.$heshop
        .search(
          'post',
          {
            include: 'setting'
          },
          {
            keyword: 'copyright_information'
          }
        )
        .then(e => {
          if (e) {
            this.form = e.content;
          }
          this.loading = false;
        })
        .catch(e => {
          this.loading = false;
          this.$message.error(e.data.message);
        });
    }
  },
  mounted() {
    this.getDetail();
    console.log(123);
  }
};
</script>

<style lang="scss" scoped>
.le-card {
  background-color: #fff;
  padding: 24px;
  border-radius: 16px;
  margin-bottom: 24px;
}

.le-head {
  margin-bottom: 24px;
}

.le-title-line {
  width: 4px;
  height: 16px;
  background: #623ceb;
  display: inline-block;
}

.le-title {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  opacity: 0.85;
  margin-left: 12px;
}

.le-tip {
  padding-left: 24px;
  height: 40px;
  line-height: 40px;
  background: #fff5d1;
  border-radius: 8px;
  margin-bottom: 25px;
}

.le-prompt-text {
  margin-top: 8px;
}

.le-file-input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.select-cover__120-edit {
  position: absolute;
  top: 0;
  background: #fff;
}
</style>
